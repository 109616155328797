import axios from "axios";
import store from "@/store";

const URL = "http://localhost:3000";
export const api = axios.create({
  baseURL: URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

api.interceptors.request.use(function (config: any) {
  const token = "Bearer " + store.getters["USER/getToken"];
  config.headers.Authorization = token;

  // const canhost = store.getters['user/getCanHost'];
  // const oidHostUser = store.getters['user/getIdProfileHost'];
  // if (canhost && oidHostUser) {
  config.headers["access-control-allow-origin"] = "*";
  // }
  return config;
});

api.interceptors.response.use(function (response: any) {
  // response.headers['Authorization'] = 'Bearer ' + store.getters['user/getAccessTokens'].mytp;
  return response;

  // }, async function (error) {
  //     // if (error.response.status === 401 && error.config.baseURL === URL) {
  //     //     const originalRequest = error.config;
  //     //     await store.dispatch('user/fetchAccessTokens');
  //     //     await store.dispatch('user/fetchMytpData');
  //     //     const accessToken = store.getters['user/getAccessTokens'].mytpAzure;
  //     //     originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
  //     //     originalRequest.withCredentials = false;
  //     //     return axios(originalRequest);
  //     // } else {
  //     //     return Promise.reject(error);
  //     // }
});

export default {
  URL,
  async getUserModules() {
    return api.get("users/me/modules");
  },
};
